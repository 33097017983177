<template>
  <div class="newmanager-info">
    <div class="img-wrap">
      <img :src="itemBg" class="itembg-img">
      <div>站长信息</div>
    </div>
    <div class="content">
      <div class="title">基本信息</div>
      <van-field :formatter="nameFormatter"
                 maxLength="20"
                 v-model="masterInfo.name"
                 label="姓名"
                 placeholder="请输入姓名"></van-field>
      <van-field :formatter="IdFormatter"
                 maxLength="18"
                 v-model="masterInfo.idCard"
                 label="身份证号"
                 placeholder="请输入身份证号"
                 @blur="IDNOBlur"
                 @click-right-icon.stop="rightChooseImg"
                 right-icon="photograph">
      </van-field>
      <van-field label="年龄" v-model="age" readonly placeholder="无需输入，自动带出"></van-field>
      <van-field clickable
                 readonly
                 v-model="currentDateval"
                 label="农历生日(选填)"
                 label-width="2.5rem"
                 placeholder="请选择"
                 @click="birthClick"
                 right-icon="arrow-down"></van-field>

      <van-uploader :preview-image="false"
                    ref="cameraImg"
                    style="display:none;"
                    capture='camera'
                    v-model="fileList"
                    :after-read="afterRead" />
      <van-field :formatter="phoneFormatter"
                 maxLength="11"
                 v-model="masterInfo.phoneNo"
                 label="电话"
                 placeholder="请输入电话号码"
                 @focus="focusInput"></van-field>

      <div class="mt2"></div>
      <van-cell title="是否为本地户口">
        <van-radio-group class="nativeRadio"
                         direction="horizontal"
                         v-model="masterInfo.isLocalResidence">
          <van-radio name="1" @click="focusInput" :disabled="!masterInfo.idCard">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio name="0" @click="focusInput" :disabled="!masterInfo.idCard">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell title="是否在本村居住">
        <van-radio-group checked-color="#3F7C53"
                         class="nativeRadio"
                         direction="horizontal"
                         v-model="masterInfo.liveVillage">
          <van-radio name="1" @click="focusInput" :disabled="!masterInfo.idCard">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio name="0" @click="focusInput" :disabled="!masterInfo.idCard">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell title="是否为党员">
        <van-radio-group checked-color="#3F7C53"
                         class="nativeRadio"
                         direction="horizontal"
                         v-model="masterInfo.partyMember">
          <van-radio name="1" @click="focusInput" :disabled="!masterInfo.idCard">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio name="0" @click="focusInput" :disabled="!masterInfo.idCard">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell title="是否为村干部">
        <van-radio-group checked-color="#3F7C53"
                         class="nativeRadio"
                         direction="horizontal"
                         v-model="masterInfo.villageCadre">
          <van-radio name="1" @click="focusInput" :disabled="!masterInfo.idCard">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio name="0" @click="focusInput" :disabled="!masterInfo.idCard">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
      </van-cell>

      <div class="mt2"></div>
      <van-field clickable
                 readonly
                 v-model="pageInfoShowVal.education"
                 label="教育状况"
                 placeholder="请选择学历"
                 @click="selectClick('education', '教育状况')"
                 right-icon="arrow-down"></van-field>
      <van-field clickable
                 readonly
                 v-model="pageInfoShowVal.marital"
                 label="婚姻状况"
                 placeholder="请选择婚姻状况"
                 @click="selectClick('marital', '婚姻状况')"
                 right-icon="arrow-down"></van-field>
      <van-field clickable
                 readonly
                 v-model="pageInfoShowVal.licenseQualify"
                 label="营业执照资质"
                 placeholder="请选择相关资质"
                 @click="selectClick('licenseQualify', '营业执照资质')"
                 right-icon="arrow-down"></van-field>
      <van-field @focus="focusInput"
                 v-model="masterInfo.professionHistory"
                 label="职业经历"
                 placeholder="请输入职业经历(选填)"
                 maxlength="10"></van-field>

      <div class="title">家庭信息</div>
      <van-field :formatter="nameFormatter"
                 maxLength="20"
                 v-model="masterInfo.linkmanName"
                 label="家属姓名"
                 placeholder="请输入家属姓名"
                 @focus="focusInput"></van-field>
      <van-field clickable
                 readonly
                 v-model="pageInfoShowVal.linkmanRelation"
                 label="家属关系"
                 placeholder="请选择家属关系"
                 @click="selectClick('linkmanRelation', '家属关系')"
                 right-icon="arrow-down"
                 visible-item-coun="0"></van-field>
      <van-field maxLength="11"
                 :formatter="phoneFormatter"
                 v-model="masterInfo.linkmanPhone"
                 label="家属电话"
                 placeholder="请输入家属电话"
                 @focus="focusInput"></van-field>

      <div class="mt2"></div>
      <van-field clickable
                 readonly
                 v-model="pageInfoShowVal.familyAssets"
                 label="家庭总资产"
                 placeholder="请选择资产范围"
                 @click="selectClick('familyAssets', '家庭总资产')"
                 right-icon="arrow-down"></van-field>
      <van-field clickable
                 readonly
                 v-model="pageInfoShowVal.familyAnnualIncome"
                 label="家庭年收入"
                 placeholder="请选择收入范围"
                 @click="selectClick('familyAnnualIncome', '家庭年收入')"
                 right-icon="arrow-down"></van-field>
      <van-field clickable
                 readonly
                 v-model="pageInfoShowVal.familyIncomeSource"
                 label="家庭收入来源"
                 placeholder="请选择家庭收入来源"
                 @click="selectClick('familyIncomeSource', '家庭收入来源')"
                 right-icon="arrow-down"
                 visible-item-coun="0"></van-field>

      <div class="mt2"></div>
      <van-cell title="家属在村委、乡镇任职">
        <van-radio-group checked-color="#3F7C53"
                         class="nativeRadio"
                         direction="horizontal"
                         v-model="masterInfo.relaInVillageOffice">
          <van-radio name="1" @click="focusInput" :disabled="!masterInfo.idCard">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio name="0" @click="focusInput" :disabled="!masterInfo.idCard">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
      </van-cell>
      <van-field clickable
                 readonly
                 v-model="pageInfoShowVal.headOfCivil"
                 label-width="4rem"
                 label="家属任民间组织负责人"
                 placeholder="请选择"
                 @click="selectClick('headOfCivil', '家属任民间组织负责人')"
                 right-icon="arrow-down"
                 visible-item-coun="0"></van-field>

      <div class="title">其它信息</div>
      <van-checkbox-group v-model="masterInfo.otherInfo"
                          class="otherRadio">
        <van-checkbox @click="focusInput"
                      checked-color="#3F7C53"
                      :disabled="!masterInfo.idCard"
                      v-for="(item,index) in selectList.otherInfo"
                      :name="item.dictKey"
                      :key="index">{{item.dictValue}}</van-checkbox>
      </van-checkbox-group>

      <div class="title">重点调查事项（必选） </div>
      <div class="pl15">
        <p>是否有非法集资、民间借贷等行为</p>
        <van-radio-group checked-color="#3F7C53"
                         class="startRadio"
                         v-model="pageInfoShowVal.isRaise">
          <van-radio :name="true">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio :name="false">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
        <p>是否有公安机关不良记录或犯罪记录</p>
        <van-radio-group checked-color="#3F7C53"
                         class="startRadio"
                         v-model="pageInfoShowVal.isCrime">
          <van-radio :name="true">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio :name="false">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
        <p>是否参与赌博活动</p>
        <van-radio-group checked-color="#3F7C53"
                         class="startRadio"
                         v-model="pageInfoShowVal.isBet">
          <van-radio :name="true">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio :name="false">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
        <p>是否有不良征信记录</p>
        <van-radio-group checked-color="#3F7C53"
                         class="startRadio"
                         v-model="pageInfoShowVal.isBadCredit">
          <van-radio :name="true">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio :name="false">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
      </div>
    </div>

    <div class="nextBtn flex" >
      <van-button class="publicBtnActive left-btn"
                  @click="holdClick" :disabled="hodeDisabled" :class="{'hodeDisabled': hodeDisabled}">暂存</van-button>
      <van-button class="publicBtnActive"
                  @click="btnAction">下一步</van-button>
    </div>

    <van-popup v-model="popupShow"
              v-if="popupShow"
              position="bottom">
    <van-picker show-toolbar
                :title="popuptitle"
                :columns="popupList"
                @cancel="popupShow = false"
                @confirm="popupConfirm" />
  </van-popup>

    <van-popup v-model="birthShow" v-if="birthShow" position="bottom">
     <!-- <van-datetime-picker v-model="currentDate" type="month-day"  title="请选择农历生日"  @cancel="birthShow = false"  @confirm="birthConfirm" :formatter="formatter"/> -->

     <van-picker v-model="currentDate" :columns="columns" show-toolbar   title="请选择农历生日"  @cancel="birthShow = false"  @confirm="birthConfirm"/>
    </van-popup>

  </div>
</template>

<script>
import common from '@/utils/common'
import { getDictByPage, getIdCardExist, idCardOcrFront, tempMasterInfo, saveMasterInfo2, getMasterInfoByNo2 } from '@/utils/api'
import {appAction,uwStatisticAction} from "@/utils/util";
import {STATION_INFO_NEXT} from "@/utils/constantNum";
import moment from 'moment'

export default {
  data () {
    return {
      itemBg:require('@/assets/img/site/itemBg.png'),
      popupVal: '',
      popupShow: false,
      popupList: [],
      popuptitle: '',
      selectList: [],
      tempObj: null,
      pageInfoShowVal: {
        education: null,
        marital: null,  
        licenseQualify: null,
        linkmanRelation: null,
        familyAssets: null,
        familyAnnualIncome: null,
        familyIncomeSource: null,
        headOfCivil: null,
        isRaise: null,
        isCrime: null,
        isBet: null,
        isBadCredit: null,
      },
      popupListArr: [],
      masterInfo: {
        name: null,
        idCard: null,
        birthdayLc: null,
        phoneNo: null,
        isLocalResidence: null,
        liveVillage: null,
        partyMember: null,
        villageCadre: null,
        relaInVillageOffice: null,
        education: '',
        marital: '',
        licenseQualify: '',
        professionHistory: '',
        linkmanName: null,
        linkmanRelation: '',
        linkmanPhone: '',
        familyAssets: '',
        familyAnnualIncome: '',
        familyIncomeSource: '',
        headOfCivil: '',
        otherInfo: [],
      },
      oldIDCard: '',
      fileList: [],
      filename: '',
      checked: require('@/assets/img/site/checked.png'),
      unchecked: require('@/assets/img/site/unchecked.png'),
      hodeDisabled: this.$route.query.status == 1 ? true : false,
      age: '',
      currentDate: '',
      birthShow: false,
      currentDateval: '',
      montharr: ['正月','二月','三月','四月','五月','六月','七月','八月','九月','十月','十一月','腊月'],
      dayarr: ['初一', '初二', '初三','初四','初五','初六','初七','初八','初九','初十','十一','十二','十三','十四','十五',
        '十六','十七','十八','十九','廿十','廿一','廿','廿三','廿四','廿五','廿六','廿七','廿八','廿九','三十'],
      columns:[
        {
          values: ['正月','二月','三月','四月','五月','六月','七月','八月','九月','十月','十一月','腊月'],
          defaultIndex: 0,
        },
        {
           values: ['初一', '初二', '初三','初四','初五','初六','初七','初八','初九','初十','十一','十二','十三','十四','十五',
        '十六','十七','十八','十九','廿十','廿一','廿二','廿三','廿四','廿五','廿六','廿七','廿八','廿九','三十'],
          defaultIndex: 0,
        }
      ]
    }
  },
  watch:{
    masterInfo:{
      handler(val){
        if(!common.getItem(common.getItem('stationNo'))) return
        if(val){
          let oldValue = common.getItem(common.getItem('stationNo')).sitezz ?common.getItem(common.getItem('stationNo')).sitezz:{}
          oldValue.name = val.name
          oldValue.idCard = val.idCard
          oldValue.birthdayLc = val.birthdayLc
          oldValue.phoneNo = val.phoneNo
          oldValue.isLocalResidence = val.isLocalResidence
          oldValue.liveVillage = val.liveVillage
          oldValue.partyMember = val.partyMember
          oldValue.villageCadre = val.villageCadre
          oldValue.relaInVillageOffice = val.relaInVillageOffice
          oldValue.education = val.education
          oldValue.marital = val.marital
          oldValue.licenseQualify = val.licenseQualify
          oldValue.professionHistory = val.professionHistory
          oldValue.linkmanName = val.linkmanName
          oldValue.linkmanRelation = val.linkmanRelation
          oldValue.linkmanPhone = val.linkmanPhone
          oldValue.familyAssets = val.familyAssets
          oldValue.familyAnnualIncome = val.familyAnnualIncome
          oldValue.familyIncomeSource = val.familyIncomeSource
          oldValue.headOfCivil = val.headOfCivil
          oldValue.otherInfo = val.otherInfo

          let cacheObj = common.getItem(common.getItem('stationNo'))
          cacheObj.sitezz = oldValue 
          common.setItem(common.getItem('stationNo'), cacheObj)
        }
      },
      deep:true
    },
    pageInfoShowVal:{
      handler(val){
        if(!common.getItem(common.getItem('stationNo'))) return
        let oldValue = common.getItem(common.getItem('stationNo')).sitezzShow ?common.getItem(common.getItem('stationNo')).sitezzShow:{}
        if(val){
          oldValue.education = val.education
          oldValue.marital = val.marital
          oldValue.licenseQualify = val.licenseQualify
          oldValue.linkmanRelation = val.linkmanRelation
          oldValue.familyAssets = val.familyAssets
          oldValue.familyAnnualIncome = val.familyAnnualIncome
          oldValue.familyIncomeSource = val.familyIncomeSource
          oldValue.headOfCivil = val.headOfCivil
          oldValue.isRaise = val.isRaise
          oldValue.isCrime = val.isCrime
          oldValue.isBet = val.isBet
          oldValue.isBadCredit = val.isBadCredit
          let cacheObj = common.getItem(common.getItem('stationNo'))
          cacheObj.sitezzShow =  oldValue
          common.setItem(common.getItem('stationNo'), cacheObj)
        }
      },
      deep: true
    }
  },
  mounted () {
    if(common.getItem('jumpFrom') != undefined){
      if(common.getItem(common.getItem('stationNo')) ){
        this.getCache()
      }else{
        let key =  this.$route.query.stationNo ? common.getItem('stationNo') : common.getItem('stationNo')
        let value = {
          sitecq: {},
          sitecqShow: {},
          sitezz: {},
          sitezzShow: {}
        }
        common.setItem(key, value)
      }
    }else{
      if(common.getItem(common.getItem('uniqueNo'))){
        this.getCache()
      }
    }
   

    getDictByPage('masterPage').then(res => {
      if (res.code === 200) {
        let data
        if (res.encryptedStatus === '1') {
          data = JSON.parse(common.decrypt(res.data, res.timestamp));
        } else {
          data = res.data
        }
        if (data.businessCode === 200) {
          let json = JSON.parse(data.data)
          this.selectList = json

          // 以防有时候返回参数不显示
          if (this.$route.query && this.$route.query.idCard && this.$route.query.idCard != 'null') {
            this.masterInfo.idCard = this.$route.query.idCard
            this.queryPageInfo()
          }
          if(this.$route.query.hasSave == 1){ // 回显
            this.getMasterInfo();
          }
        }
      }
    });
    uwStatisticAction('/newsSiteZhanzhang','新建站点站长填写')
  },
  methods: {
    formatter(type, val){
      if (type === 'month') {
        return this.montharr[ Number(val)-1];
      }
      if (type === 'day') {
        return this.dayarr[Number(val)-1];
      }
      return val;
    },
    birthConfirm(val) {
      this.currentDateval = val[0] + val[1]
      let month = (this.montharr.indexOf(val[0]) + 1).toString().length >1 ? this.montharr.indexOf(val[0]) + 1 : '0' + (this.montharr.indexOf(val[0]) + 1)
      let day = (this.dayarr.indexOf(val[1]) +1).toString().length > 1 ? this.dayarr.indexOf(val[1]) + 1 : '0' + (this.dayarr.indexOf(val[1]) +1) 
      this.masterInfo.birthdayLc = month +''+ day
      console.log(this.masterInfo.birthdayLc)
      this.birthShow = false
    },
    birthClick() {
      this.birthShow = true
    },
    getCache() {
      let val = common.getItem(common.getItem('stationNo')).sitezz ?common.getItem(common.getItem('stationNo')).sitezz:{}
      if(val){
        this.masterInfo.name = val.name
        this.masterInfo.idCard = val.idCard
        this.masterInfo.birthdayLc = val.birthdayLc
        this.masterInfo.phoneNo = val.phoneNo
        this.masterInfo.isLocalResidence = val.isLocalResidence
        this.masterInfo.liveVillage = val.liveVillage
        this.masterInfo.partyMember = val.partyMember
        this.masterInfo.villageCadre = val.villageCadre
        this.masterInfo.relaInVillageOffice = val.relaInVillageOffice
        this.masterInfo.education = val.education
        this.masterInfo.marital = val.marital
        this.masterInfo.licenseQualify = val.licenseQualify
        this.masterInfo.professionHistory = val.professionHistory
        this.masterInfo.linkmanName = val.linkmanName
        this.masterInfo.linkmanRelation = val.linkmanRelation
        this.masterInfo.linkmanPhone = val.linkmanPhone
        this.masterInfo.familyAssets = val.familyAssets
        this.masterInfo.familyAnnualIncome = val.familyAnnualIncome
        this.masterInfo.familyIncomeSource = val.familyIncomeSource
        this.masterInfo.headOfCivil = val.headOfCivil
        this.masterInfo.otherInfo = val.otherInfo
      }
      let valShow = common.getItem(common.getItem('stationNo')).sitezzShow ?common.getItem(common.getItem('stationNo')).sitezzShow:{}
      if(valShow){
        this.pageInfoShowVal.education = valShow.education
        this.pageInfoShowVal.marital = valShow.marital
        this.pageInfoShowVal.licenseQualify = valShow.licenseQualify
        this.pageInfoShowVal.linkmanRelation = valShow.linkmanRelation
        this.pageInfoShowVal.familyAssets = valShow.familyAssets
        this.pageInfoShowVal.familyAnnualIncome = valShow.familyAnnualIncome
        this.pageInfoShowVal.familyIncomeSource = valShow.familyIncomeSource
        this.pageInfoShowVal.headOfCivil = valShow.headOfCivil
        this.pageInfoShowVal.isRaise = valShow.isRaise
        this.pageInfoShowVal.isRaise = valShow.isRaise
        this.pageInfoShowVal.isCrime = valShow.isCrime
        this.pageInfoShowVal.isBet = valShow.isBet
        this.pageInfoShowVal.isBadCredit = valShow.isBadCredit
      }
    },
    holdClick() {
      if (!this.masterInfo.name) {
        this.$toast('请输入站长姓名')
        return
      }
      if (!this.masterInfo.idCard) {
        this.$toast('请输入站长身份证号')
        return
      }
      if(!this.masterInfo.phoneNo){
        this.$toast('请输入电话号码')
        return
      }
      let invesJson = {
        "isRaise": "NO",
        "isCrime": "NO",
        "isBet": "NO",
        "isBadCredit": "NO"
      }
      let data = {
        stationNo: this.$route.query.stationNo,
        surveyItem: JSON.stringify(invesJson),
      }

      let obj = Object.assign(data, this.masterInfo)
      let params = {}
      for (let key in obj) {
        if (obj[key] != null && obj[key] != '' && obj[key] != undefined) {
          params[key] = obj[key]
        }
      }
      if (params.otherInfo) {
        params.otherInfo = params.otherInfo.toString()
      } else {
        params.otherInfo = ''
      }
      tempMasterInfo(params).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            this.$toast('信息保存成功')
          }else{
            this.$toast(res.data.businessMessage)
          }
        }
      })
    },
    rightChooseImg () {
      this.$refs.cameraImg.chooseFile()
    },
    afterRead (file) {
      console.log(file)
      // 大于15M
      if (file.file.size > 15 * 1024 * 1024) {
        Toast('图片太大，请上传小于15M的图片')
        return
      }
      if (!common.imageVerify(file.file.type)) {
        this.fileList.splice(detail.index, 1)
        Toast('上传图片必须为png或者jpg格式')
        return
      }
      // 小于100K不进行压缩
      if (file.file.size < 100 * 1024) {
        this.saveFile(file.content)
        return
      }
      this.filename = file.file.name
        ? file.file.name
        : "defaultname";
      let reader = new FileReader()
      reader.readAsDataURL(file.file)
      let _this = this
      reader.onload = function (e) {
        let content = file.content //图片的src，base64格式
        let img = new Image()
        img.src = content
        img.onload = function () {
          common.compress(img, (dataURL) => {
            _this.saveFile(dataURL)
          })
        }
      }
    },
    saveFile (dataURL) {
      let params = {
        fileContent: dataURL
      }
        idCardOcrFront(params).then(res => {
        if (res.code === 200) {
          if (res.data.businessCode === 200) {
           this.masterInfo.idCard = res.data.idCard
            this.masterInfo.name = res.data.userName
          }
        }
      })
    },
    IDNOBlur () {
      if (!this.masterInfo.idCard) {
        this.age = ''
        return
      }
      if (!common.IdCardVerify(this.masterInfo.idCard)) {
        this.$toast('站长身份证格式不正确')
        this.age = ''
        return
      }
      // 先不管回显
      this.queryPageInfo()
    },
    nameFormatter (val) {
      return val.replace(/[^\u4e00-\u9fa5.]/g, '')
    },
    IdFormatter (val) {
      return val.replace(/[^\w.v]/ig, "")
    },
    phoneFormatter (val) {
      if (!this.masterInfo.idCard) {
        return ''
      }
      return val.replace(/[^\d]/g, "")
    },
    focusInput () {
      if (!this.masterInfo.idCard) {
        this.$toast('请先输入身份证号')
        return
      }
    },
    selectClick (obj, title) {
      if (!this.masterInfo.idCard) {
        this.$toast('请先输入身份证号')
        return
      }
      this.popupShow = true
      this.popupList = []
      this.popuptitle = title
      for (let obj of this.selectList[obj]) {
        this.popupList.push(obj.dictValue)
      }
      this.popupListArr = this.selectList[obj]
      this.tempObj = obj
    },
    popupConfirm (value, index) {
      this.popupShow = false
      this.pageInfoShowVal[this.tempObj] = value
      this.masterInfo[this.tempObj] = this.popupListArr[index].dictKey
    },
    btnAction () {
      if (!this.masterInfo.name) {
        this.$toast('请输入站长姓名')
        return
      }
      if (!this.masterInfo.idCard) {
        this.$toast('请输入站长身份证号')
        return
      }
      if (this.masterInfo.idCard.length < 15) {
        this.$toast('站长身份证格式不正确')
        return
      }
      if (!common.IdCardVerify(this.masterInfo.idCard)) {
        this.$toast('站长身份证格式不正确')
        return
      }
      if (!this.masterInfo.phoneNo) {
        this.$toast('请输入站长电话')
        return
      }
      if (!common.phoneVerify(this.masterInfo.phoneNo.replace(/\s+/g, ""))) {
        this.$toast('站长电话格式不正确')
        return
      }
      if (!this.masterInfo.isLocalResidence) {
        this.$toast('请选择站长是否为本地户口')
        return
      }
      if (!this.masterInfo.liveVillage) {
        this.$toast('请选择站长是否在本村居住')
        return
      }
      if (!this.masterInfo.partyMember) {
        this.$toast('请选择站长是否为党员')
        return
      }
      if (!this.masterInfo.villageCadre) {
        this.$toast('请选择站长是否为村干部')
        return
      }
      if (!this.masterInfo.education) {
        this.$toast('请选择站长教育状况')
        return
      }
      if (!this.masterInfo.marital) {
        this.$toast('请选择站长婚姻状况')
        return
      }
      if (!this.masterInfo.licenseQualify) {
        this.$toast('请选择站长营业执照资质')
        return
      }
      if (this.masterInfo.professionHistory && (this.masterInfo.professionHistory.length < 4 || this.masterInfo.professionHistory.length > 10)) {
        this.$toast('请输入4-10字的职业经历')
        return
      }
      if (!this.masterInfo.linkmanName) {
        this.$toast('请输入家属姓名')
        return
      }
      if (!this.masterInfo.linkmanRelation) {
        this.$toast('请选择家属关系')
        return
      }
      if (!this.masterInfo.linkmanPhone) {
        this.$toast('请输入家属电话')
        return
      }
      if (!common.phoneVerify(this.masterInfo.linkmanPhone.replace(/\s+/g, ""))) {
        this.$toast('家属电话格式不正确')
        return
      }
      if (!this.masterInfo.familyAssets) {
        this.$toast('请选择站长家庭总资产')
        return
      }
      if (!this.masterInfo.familyAnnualIncome) {
        this.$toast('请选择站长家庭年收入')
        return
      }
      if (!this.masterInfo.familyIncomeSource) {
        this.$toast('请选择站长家庭收入来源')
        return
      }
      if (!this.masterInfo.relaInVillageOffice) {
        this.$toast('请选择家属是否在村委、乡镇任职')
        return
      }
      if (!this.masterInfo.headOfCivil) {
        this.$toast('请选择家属任民间组织负责人')
        return
      }
      // console.log(this.masterInfo.otherInfo)
      // if (this.masterInfo.otherInfo.length == 0) {
      //   this.$toast('其他信息不能为空')
      //   return
      // }
      if (this.pageInfoShowVal.isRaise == null) {
        this.$toast('请选择是否有非法集资、民间借贷等行为')
        return
      }
      if (this.pageInfoShowVal.isCrime == null) {
        this.$toast('请选择是否有公安机关不良记录或犯罪记录')
        return
      }
      if (this.pageInfoShowVal.isBet == null) {
        this.$toast('请选择是否参与赌博活动')
        return
      }
      if (this.pageInfoShowVal.isBadCredit == null) {
        this.$toast('是否有不良征信记录')
        return
      }
      if (this.pageInfoShowVal.isRaise || this.pageInfoShowVal.isCrime || this.pageInfoShowVal.isBet || this.pageInfoShowVal.isBadCredit) {
        this.$dialog.alert({
          title: '提示',
          message: '站长候选人不符合选用标准，无法完成建站申请',
          confirmButtonText: '我知道了'
        })
        return
      }

      let invesJson = {
        "isRaise": "NO",
        "isCrime": "NO",
        "isBet": "NO",
        "isBadCredit": "NO"
      }
      let data = {
        stationNo: this.$route.query.stationNo,
        surveyItem: JSON.stringify(invesJson),
      }

      let obj = Object.assign(data, this.masterInfo)
      let params = {}
      for (let key in obj) {
        if (obj[key] != null && obj[key] != '' && obj[key] != undefined) {
          params[key] = obj[key]
        }
      }
      if (params.otherInfo) {
        params.otherInfo = params.otherInfo.toString()
      } else {
        params.otherInfo = ''
      }
      saveMasterInfo2(params).then(res => {
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp))
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            common.setItem('masterNo', data.uniqueNo)
            let p = {
              stationNo:this.$route.query.stationNo
            }
            appAction(STATION_INFO_NEXT,p)
            this.$router.push({
              name: 'stationAgentScore',
              query: {
                score: JSON.stringify(data),
                masterName: this.masterInfo.name,
                stationNo: this.$route.query.stationNo
              }
            })
          } else {
            this.$toast(data.businessMessage)
          }
        } else {
          this.$toast(res.message)
        }
      })
    },
    queryPageInfo () {
      let para = {
        idCard: this.masterInfo.idCard
      }
      getIdCardExist(para).then(res => {
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            let json = data
            if (json.idCardExist != 0 && this.oldIDCard != this.masterInfo.idCard) {
              this.$toast('该身份证号已存在,请重新输入')
              this.masterInfo.idCard = '';
            }else{
              this.age = common.getAge(this.masterInfo.idCard)
            }
          }
        }
      })
    },
    // 获取站长信息
    getMasterInfo () {
      getMasterInfoByNo2({ masterNo: this.$route.query.masterNo }).then(res => {
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            this.setMastrInfo(data.data);
            this.age = common.getAge(data.data.idCard)
            if(data.data.birthdayLc){
              this.currentDate = data.data.birthdayLc
              this.columns[0].defaultIndex = Number(data.data.birthdayLc.substr(0,2)) -1
              this.columns[1].defaultIndex = Number(data.data.birthdayLc.substr(2,2)) -1

              this.currentDateval = this.montharr[Number(data.data.birthdayLc.substr(0,2))-1] + this.dayarr[Number(data.data.birthdayLc.substr(2,2))-1]
            }
          } else {
            // this.$toast(data.businessMessage)
          }
        }
      })
    },
    setMastrInfo (masterInfo) {
      var infoArray = ['idCard', 'name', 'birthdayLc', 'phoneNo', 'isLocalResidence', 'liveVillage', 'partyMember', 'villageCadre', 'linkmanName', 'linkmanPhone', 'professionHistory', 'relaInVillageOffice'];
      this.setInputInfo(infoArray, masterInfo);
      this.oldIDCard = this.masterInfo.idCard;
      var selectInfoArray = ['education', 'marital', 'licenseQualify', 'linkmanRelation', 'familyAssets', 'familyAnnualIncome', 'familyIncomeSource', 'headOfCivil', 'familyAssets', 'familyAssets'];
      for (var nameString of selectInfoArray) {
        this.setSelectedInfo(this.selectList[nameString], masterInfo[nameString], nameString);
      }
      this.masterInfo.otherInfo = masterInfo.otherInfo.split(',');
      
      this.pageInfoShowVal.isRaise = false
      this.pageInfoShowVal.isCrime = false
      this.pageInfoShowVal.isBet = false
      this.pageInfoShowVal.isBadCredit = false    
    },
    setInputInfo (infoArray, itemsDic) {
      console.log('infoArray', infoArray)
      for (var obj of infoArray) {
        this.masterInfo[obj] = ( itemsDic[obj] || itemsDic[obj] == 0) ? itemsDic[obj].toString() : itemsDic[obj];
      }
    },
    setSelectedInfo (array, keyString, name) {
      console.log(name);
      for (let oneDict of array) {
        console.log(array);
        if (oneDict.dictKey == keyString) {
          this.pageInfoShowVal[name] = oneDict.dictValue;
          this.masterInfo[name] = keyString;
        }
      }
      return '';

    },
    // 获取配置文字
    getValue (array, keyString) {
      console.log(array, keyString);
      for (let oneDict of array) {
        if (oneDict.dictKey == keyString) {
          return oneDict.dictValue;
        }
      }
      return '';
    },
  }
}
</script>

<style lang="less" scoped>
  .newmanager-info{
    min-height: 100%;
    background: #F7F8F9;
    position: relative;
    .itembg-img{
      width: 100%;
    }
    .img-wrap{
      position: relative;
      div{
        color: #fff;
        font-size: 0.48rem;
        position: absolute;
        top: 1.5rem;
        left: 0.3rem;
      }
    }
    .content{
      background: #F7F8F9;
      width: 100%;
      border-top-left-radius: 0.4rem;
      border-top-right-radius: 0.4rem;
      margin-top: -1.3rem;
      position: relative;
      padding-bottom: 2rem;
      .title {
        text-align: left;
        background: #ffffff;
        height: 0.8rem;
        line-height: 0.8rem;
        padding: 0 0.3rem;
        color: #333333;
        font-weight: bold;
        font-size: 15px;
        margin-top: 0.2rem;

      }
      div.title:before {
        width: 8px;
        height: 5px;
        color: #3f7c53;
        content: "| ";
      }
    }
    .flex {
      display: flex;
    }
    .mt2 {
      margin-top: 0.2rem;
    }
    .img-icon{
      width:0.4rem;
    }
    .title {
      text-align: left;
      background: #ffffff;
      height: 0.8rem;
      line-height: 0.8rem;
      padding: 0 0.3rem;
      color: #333333;
      font-weight: bold;
      font-size: 15px;
      margin-top: 0.2rem;
    }
    .pl15 {
      padding: 0.1rem 0.3rem;
      background: #fff;
    }
    .van-checkbox {
      margin: 0.3rem 0;
    }
    .nextBtn {
      width: 100%;
      padding: 12px;
      background: #fff;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 11;
      .left-btn{
        background: #fff;
        color:#333;
        border: 1px solid #E5E5E5;
        margin-right: 0.24rem;
      }
      .hodeDisabled{
        color: #E5E5E5;
        background: #fff;
      }
    }
  }
</style>
<style>
  .siteInfo .van-tab__text{
    font-size: 15px;
  }
  .siteInfo .van-cell{
    min-height: 48px;
  }
  .siteInfo .van-cell__title{
    font-size: 15px;
    color: #333333;
  }
  .siteInfo .van-field__control {
    font-size: 15px;
  }
  .siteInfo .van-radio{
    font-size: 15px;
  }
  .siteInfo .van-field__clear, .van-field__right-icon{
    line-height: 0.35rem;
  }
  .itembg-img{
    width: 100%;
  }
  .newsite-wrap{
    min-height: 100%;
    background: #F7F8F9;
    position: relative;
  .itembg-img{
    width: 100%;
  }
  .img-wrap{
    position: relative;
  div{
    color: #fff;
    font-size: 0.48rem;
    position: absolute;
    top: 1.5rem;
    left: 0.3rem;
  }
  }
  .nextBtn {
    width: 100%;
    padding: 12px;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 11;
  .left-btn{
    background: #fff;
    color:#333;
    border: 1px solid #E5E5E5;
    margin-right: 0.24rem;
  }
  .hodeDisabled{
    color: #E5E5E5;
    background: #fff;
  }
  }
  .content{
    background: #F7F8F9;
    width: 100%;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    margin-top: -1.3rem;
    position: relative;
    padding-bottom: 0.5rem;
  .title {
    text-align: left;
    background: #ffffff;
    height: 0.8rem;
    line-height: 0.8rem;
    padding: 0 0.3rem;
    color: #333333;
    font-weight: bold;
    font-size: 15px;
    margin-top: 0.2rem;
  &:nth-child(1){
     margin-top: 0;
   }
  }
  div.title:before {
    width: 8px;
    height: 5px;
    color: #3f7c53;
    content: "| ";
  }
  }
  .flex {
    display: flex;
  }
  .img-icon{
    width:0.4rem;
  }
  .newsite-flex {
    padding-bottom: 0.2rem;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    color: #3f7c53;
  }
  .mt2 {
    margin-top: 0.2rem;
  }
  .title {
    text-align: left;
    background: #ffffff;
    height: 0.8rem;
    line-height: 0.8rem;
    padding: 0 0.3rem;
    color: #333333;
    font-weight: bold;
    font-size: 15px;
    margin-top: 0.2rem;
  }
  div.title:before {
    width: 8px;
    height: 5px;
    color: #3f7c53;
    content: "| ";
  }
  .tab-content {
    padding-bottom: 1rem;
  }
  .radiogroup {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .checkboxitem {
    background: #fff;
    height: 0.8rem;
    line-height: 0.8rem;
    padding: 0.2rem 0.32rem;
  }

  }

</style>
<style lang="less">
  @import "../../style/putInto.less";

</style>